<template>
  <div class="addScenery">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加商品</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="基本信息" name="1">
        <el-form ref="form" label-width="80px">
          <el-form-item label="商品排序" v-if="id >= 0">
            <el-input-number
              style="width:200px"
              v-model="formList.prodSort"
              :precision="0"
              :step="1"
              placeholder="请输入商品排序"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input
              v-model="formList.prodName"
              placeholder="请输入商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属分类" class="street">
            <el-select
              placeholder="请选择一级分类"
              v-model.trim="formList.prodParentType"
              style="width:200px"
              @change="changeType"
            >
              <el-option
                v-for="item in list"
                :key="item.typeName"
                :label="item.typeName"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
            <el-select
              style="width:200px"
              v-show="formList.prodParentType >= 0"
              placeholder="请选择二级分类"
              v-model.trim="formList.prodType"
            >
              <el-option
                v-for="item in list2"
                :key="item.typeName"
                :label="item.typeName"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属店铺" class="street">
            <el-select
              placeholder="请选择商品所属店铺"
              v-model.trim="formList.prodStoreId"
              style="width:200px"
            >
              <el-option
                v-for="item in shopList"
                :key="item.storeId"
                :label="item.storeName"
                :value="item.storeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式">
            <el-select
              @change="changePayWay"
              style="width:200px"
              placeholder="请选择支付方式"
              v-model.trim="formList.prodPayWay"
            >
              <el-option :key="3" label="积分+现金" :value="3"></el-option>
              <el-option :key="1" label="积分" :value="1"></el-option>
              <el-option :key="2" label="现金" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="价格">
            <el-input-number
              style="width:200px"
              v-model="formList.prodPrice"
              :precision="2"
              :step="0.01"
              :disabled="formList.prodPayWay==1"
              placeholder="请输入商品价格"
            >
            </el-input-number>
            <p
              v-if="formList.prodPayWay==1"
              style="margin-left:10px; color:#e6a23c"
            >
              纯积分支付时,价格为0
            </p>
          </el-form-item>
                <el-form-item label="销售平台">
        <el-checkbox 
        v-model="checkAll" 
        @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group 
        v-model="checked" 
        @change="handleCheckedCitiesChange">
        <el-checkbox 
        v-for="item in platform" 
        :label="item.value" :key="item.value">{{item.name}}</el-checkbox>
      </el-checkbox-group>
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="formList.supplierId" placeholder="请选择">
          <el-option
            v-for="item in supplierOptions"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算价">
        <el-input
          v-model="formList.settlePrice"
          placeholder="请输入结算价"
        ></el-input>
      </el-form-item>
          <el-form-item
            label="抵扣积分"
            v-if="formList.prodPayWay==1||formList.prodPayWay==3"
          >
            <el-input-number
              :min="0"
              :step="1"
              :precision="0"
              placeholder="请输入积分"
              v-model="formList.prodIntegral"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="热门推荐">
            <el-radio-group
              v-model="formList.prodIsHot"
              style="display:flex; align-items:center; height:40px"
            >
              <el-radio :label="1">否</el-radio>
              <el-radio :label="0">是</el-radio>
            </el-radio-group>
          </el-form-item>
           <el-form-item label="是否售后">
          
              <el-radio v-model="formList.isExit" :label="1">是</el-radio>
              <el-radio v-model="formList.isExit" :label="0">否</el-radio>
         
          </el-form-item>
          <el-form-item label="库存">
            <el-input-number
              style="width:200px"
              v-model="formList.prodRepertory"
              :precision="0"
              :step="1"
              placeholder="请输入商品库存"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="标签" class="tag_box">
            <el-tag
              :key="tag"
              v-for="tag in formList.prodTag"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              style="width:200px"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ 添加标签</el-button
            >
          </el-form-item>
          <el-form-item label="封面图">
            <send-image
              :images="formList.prodHomeImg"
              @addImageStr="addImageStr"
              v-if="
                Number(id)==0||(Number(id) > 0&&formList.prodName!=='')
              "
            ></send-image>
          </el-form-item>
          <el-form-item>
            <span style="color:red">
              每张图片不能超过500K，建议尺寸：165*165px</span
            >
          </el-form-item>
          <el-form-item label="展示图">
            <send-image
              type="more"
              :images="formList.prodBannerImg.join(',')"
              @addImageStrs="addImageStrs"
              v-if="
                Number(id)==0||(Number(id) > 0&&formList.prodName!=='')
              "
            ></send-image>
          </el-form-item>
          <el-form-item>
            <span style="color:red"
              >每张图片不能超过500K，建议尺寸：375*375px</span
            >
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" @click="onSave" v-if="id >= 0"
            >保存</el-button
          >
          <el-button type="primary" @click="activeName = '2'">下一步</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="商品规格" name="2">
        <el-form ref="form" label-width="80px">
          <el-button type="primary" @click="addSpec" v-if="specGroup.length < 3"
            >添加规格组</el-button
          >
          <div
            v-if="specGroup.length > 0"
            style="width:100%; height:30px"
          ></div>
          <div class="spec_box" v-for="(item, index) in specGroup" :key="index">
            <div class="spec_item"> 
              <el-input
                v-model="item.group_name"
                @input="getTableData()"
              ></el-input>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="small"
                @click="removeSpec(index)"
              >
              </el-button>
            </div>
            <div class="spec_sub_box">
              <el-button
                type="primary"
                @click="addSubSpec(index)"
                class="spec_sub_btn"
                >添加规格项</el-button
              >
              <div
                class="spec_sub_item"
                v-for="(val, num) in item.storeProdSpecItems"
                :key="val.item_img"
              >
                <div class="s_i_t"> 
                  <el-input
                    v-model="val.item_name"
                    @input="getTableData()"
                  ></el-input>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    v-if="num > 0"
                    @click="removeSubSpec(index, num)"
                  >
                  </el-button>
                </div>
                <div class="img" v-if="index==0&&val.item_img">
                  <el-image
                    style="width:80px; height:80px"
                    :src="val.item_img"
                    :preview-src-list="[val.item_img]"
                  ></el-image>
                  <div
                    class="el-icon-close"
                    @click="onClose2(index, num)"
                  ></div>
                </div>
                <div class="addImg" v-if="index==0&&!val.item_img">
                  <input
                    type="file"
                    @change="(e) => onFileChange2(e, index, num)"
                  />
                  <i class="el-icon-plus"></i>
                </div>
              </div>
            </div>
            <span style="color:red" v-if="index==0">
              建议尺寸100*100px
            </span>
          </div>
        </el-form>

        <!-- 规格表 -->
        <h3 v-if="specGroup&&specGroup.length > 0" style="margin:24px 0 0">
          规格表:
        </h3>
        <el-table
          v-if="specGroup&&specGroup.length > 0"
          :data="specComb"
          style="width:100%; max-width:1200px"
        >
          <el-table-column
            v-for="(item, index) in specGroup"
            :key="index"
            :prop="
              index==0
              ?'comb_one_item_name'
                :index==1
              ?'comb_two_item_name'
                :index==2
              ?'comb_three_item_name'
                :''
            "
            :label="item.group_name"
            width="180"
          >
          </el-table-column>
          <el-table-column prop="comb_price" label="价格" width="160">
            <template slot-scope="scope">
              <el-input-number
                :disabled="formList.prodPayWay==1"
                size="small"
                :min="0"
                :step="1"
                :precision="2"
                v-model="scope.row.comb_price"
                @input="inpChange"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="formList.prodPayWay==1||formList.prodPayWay==3"
            prop="comb_repertory"
            label="积分"
            width="160"
          >
            <template slot-scope="scope">
              <el-input-number
                size="small"
                :min="0"
                :step="1"
                :precision="0"
                v-model="scope.row.comb_integral"
              />
            </template>
          </el-table-column>
          <el-table-column prop="comb_repertory" label="库存" width="160">
            <template slot-scope="scope">
              <el-input-number
                size="small"
                :min="0"
                :step="1"
                :precision="0"
                v-model="scope.row.comb_repertory"
                @input="inpChange"
              />
            </template>
          </el-table-column>
        </el-table>

        <div class="btn">
          <el-button type="primary" @click="activeName = '1'">上一步</el-button>
          <el-button type="primary" @click="onSave" v-if="id >= 0"
            >保存</el-button
          >
          <el-button type="primary" @click="activeName = '3'">下一步</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="详细说明" name="3">
        <el-form ref="form" label-width="80px">
          <el-form-item label="商品详情">
            <!-- <quill-editor
              class="richEdit"
              v-model="formList.prodEsp"
              :options="quillOption"
              ref="myTextEditor"
            >
            </quill-editor> -->
             <VueUeditorWrap v-model="formList.prodEsp" :config="myConfig"  ref="myTextEditor"></VueUeditorWrap>
          </el-form-item>
          <el-form-item label="">
            <span style="color:red">
              每张图片不能超过500K，建议尺寸：375*375px
            </span>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" @click="activeName = '2'">上一步</el-button>
          <el-button type="primary" @click="onSave">保存</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
const platform = [{value:1,name:'小程序'}, {value:2,name:'H5'}, {value:3,name:'IOS'},{value:4,name:'Android'},{value:5,name:'PC'}];
import myMap from "../../components/map.vue";
import { sendFile } from "../../api/sendFile";
import quillConfig from "../../../quill-config";
import { couponAllCateAPI } from "../../api/coupon";
import { supList } from "../../api/supList.js";
import VueUeditorWrap from "vue-ueditor-wrap";
import {
  typeList,
  shopList,
  productAdd,
  productEdit,
  productDetail,
} from "../../api/selectMall";
import SendImage from "../../components/sendImage.vue";
export default {
  name:"addScenery",
  components:{
    myMap:myMap,
    SendImage,
    VueUeditorWrap
  },
  data() {
    return {
      supplierOptions:[
        {cateId:0,cateName:'自营'}
        ],
      checkAll: false,
      checked: [],
      checkVal:[],
      platform: platform,
      myConfig: {
        toolbars: [
          [
            'simpleupload', //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
        serverUrl: "/config",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "100000000",
      },
      id:undefined,
      quillOption:quillConfig,
      activeName:"1",
      typeValue:"",
      list:[],
      list2:[],
      shopList:[],
      imgList:[],
      specGroup:[],
      specComb:[],
      inputVisible:false,
      inputValue:"",
      column:[],
      formList:{
        prodIsHot:1,
        prodSort:1,
        prodName:"",
        prodPrice:undefined,
        prodRepertory:undefined,
        prodType:"",
        prodParentType:"",
        prodIntegral:"",
        prodHomeImg:"",
        prodBannerImg:[],
        prodPayWay:"",
        prodStoreId:"",
        prodTag:[],
        prodEsp:"",
        sellPlace:'0',
        settlePrice:'',
        supplierId:'',
        isExit:0,
      },
    };
  },
  watch:{
    imgList:function () {
      this.formList.imgUrl = this.imgList.join(",");
    },
  },
  created() {
    this.id = this.$route.query.id||0;
    if (this.id) this.getData();
    this.getTypeList();
    this.getStoreList();
    this.init()
    // this.getCate()
    this.getSupplierList()
  },
  methods:{
     getSupplierList() {
      supList().then((res) => {

        this.supplierOptions.push(...res.data.data) ;
      });
    
    },
    async getCate(){
        const {data} = await couponAllCateAPI()
        let arr = data.data
      arr.map((e)=>{
  this.supplierOptions.push({'supplierId':e.cateId,'label':e.cateName})
      })
    },
 init(){
       for(let i = 0 ; i <this.platform.length;i++){
        this.checkVal.push(this.platform[i].value)
      }     
    },
        handleCheckAllChange(val) {   
      this.checkVal = []
      if(val){
        this.init()
        console.log('if',this.checkVal)
        this.checked = this.checkVal
        this.formList.sellPlace = '0'
      }else{
      this.checked = []
      }
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.platform.length;
        this.formList.sellPlace = this.checked.toString()
        console.log(value,'value')
      },
    async getData() {
      const { data } = await productDetail({ prodId:this.id });
      console.log(data,'data')
      // this.formList.supplierId = data.supplierId;
      this.formList.settlePrice = data.data.settlePrice;
              
          try{
         let arr  = data.data.sellPlace.split(',')
      let newArr = []
      arr.map((e)=>{
        newArr.push(Number(e))
      })
      if(newArr[0]==0 || newArr.length == this.platform.length){
          this.checkAll = !this.checkAll
          console.log(this.checkVal,'val')
          this.checked = this.checkVal
        }else{
          this.checkVal = newArr
          this.checked = this.checkVal
        }
     }catch(e){
       console.log(e)
     }
      if (data.code==0) {
        this.specGroup = data.data.specGroups||[];
        this.specComb = data.data.specCombs||[];
        for (var k in this.formList) {
          this.formList[k] = data.data[k];
          if (this.formList[k]==null) this.formList[k] = "";
          if (k=="prodTag"||k=="prodBannerImg")
            this.formList[k] = data.data[k].split(",");
        }
        if (this.formList.prodParentType >= 0) {
          this.changeType(this.formList.prodParentType);
        }
      } else {
        this.$message({
          type:"error",
          message:data.msg,
        });
      }
    },
    async onSave() {
      // 处理商品信息
      for (const key in this.formList) {
        let forItem = this.formList[key];
        if (
          forItem===null||
          forItem===""||
          forItem===undefined||
          forItem.length===0
        ) {
          console.log(
            "提交失败==",
            "参数名:",
            key,
            " 获取值：",
            this.formList[key]
          );

          if (key=="prodIntegral"&&this.formList.prodPayWay==2) {
            // 现金支付时不校验积分字段
            console.log("支付类型", this.formList.prodPayWay);
          } else {
            this.$message({ type:"error", message:"请把数据填写完整" });
            return;
          }
        }
      }
      if (this.formList.prodBannerImg.length==0) {
        this.$message({
          type:"error",
          message:"请上传店铺轮播图",
        });
        return;
      }

      // 处理规格相关
      let specData = {};
      if (this.specGroup.length > 0) {
        let msg = "";
        this.specGroup.forEach((item, index) => {
          // console.log(item)
          if (!item.group_name) {
            msg = `请填写第${index + 1}个规格组名称`;
          } else if (index==0) {
            for (var j = 0; j < item.storeProdSpecItems.length; j++) {
              if (!item.storeProdSpecItems[j].item_img) {
                msg = `请上传第${index + 1}个规格组第${j + 1}个规格项的图片`;
                break;
              }
            }
          }
        });
        if (msg) {
          this.$message({ type:"error", message:msg });
          return;
        }
        this.specComb.forEach((item, index) => {
          console.log(item);
          for (var k in item) {
            if (item[k]===""||item[k]===undefined) {
              msg = `请检查规格表第${index + 1}列数据`;
              if (k=="comb_integral"&&this.formList.prodPayWay==2) {
                item[k] = 0;
                msg = "";
              }
            }
          }
        });
        if (msg) {
          this.$message({ type:"error", message:msg });
          return;
        }
        specData.specGroup = this.specGroup;
        specData.specComb = this.specComb;
      }

      let formData = { ...this.formList };
      formData.prodTag = formData.prodTag.join(",");
      formData.prodBannerImg = formData.prodBannerImg.join(",");

      let data = {};
      if (this.id >= 0) {
        // data = await productAdd({ ...formData, ...specData });
        data = await productEdit({
          ...formData,
          ...specData,
          prodId:this.id,
        });
      } else {
        data = await productAdd({ ...formData, ...specData });
      }
      data = data.data;
      if (data.code==0) {
        this.$router.go(-1);
        this.$message({
          type:"success",
          message:data.msg,
        });
      } else {
        this.$message({
          type:"error",
          message:data.msg,
        });
      }
    },
    // 切换支付方式
    changePayWay(val) {
      if (val==1) {
        this.formList.prodPrice = 0;
        this.specComb.forEach((item) => {
          item.comb_price = 0.0;
        });
      }
    },
    // 切换一级分类
    changeType:async function (val) {
      const { data } = await typeList({
        currentPage:1,
        pageSize:999,
        typeGrade:2,
        parentId:val,
      });
      this.list2 = data.list;
    },
    // 获取分类
    async getTypeList() {
      const { data } = await typeList({
        currentPage:1,
        pageSize:999,
        typeGrade:1,
      });
      this.list = data.list;
    },
    // 获取店铺
    async getStoreList() {
      const { data } = await shopList({
        currentPage:1,
        pageSize:999,
      });
      this.shopList = data.list;
    },
    // 添加规格组
    addSpec() {
      let count = (this.specGroup.length||0) + 1;
      this.specGroup.push({
        group_name:"规格组" + count,
        storeProdSpecItems:[
          {
            item_name:"规格项" + count + "-1",
            comb_price:"",
            comb_integral:"",
            comb_repertory:"",
          },
        ],
      });
      this.getTableData();
    },
    // 删除规格组
    removeSpec(index) {
      this.specGroup.splice(index, 1);
      this.getTableData();
    },
    // 添加规格项
    addSubSpec(index) {
      let count = index + 1;
      let count2 = this.specGroup[index].storeProdSpecItems.length + 1;
      let data = {
        item_name:"规格项" + count + "-" + count2,
        comb_price:"",
        comb_integral:"",
        comb_repertory:"",
      };
      if (index==0) data.item_img = "";
      this.specGroup[index].storeProdSpecItems.push(data);
      this.getTableData();
    },
    // 删除规格项
    removeSubSpec(index, subIndex) {
      this.specGroup[index].storeProdSpecItems.splice(subIndex, 1);
      this.getTableData();
    },
    // 生成表格数据
    getTableData() {
      if (this.specGroup.length==0) {
        this.specComb = [];
        return;
      }
      // let strArr = []
      let arr = this.specGroup.map((item) => {
        // strArr.push(`specItem_${item}`)
        return item.storeProdSpecItems.map((item) => {
          return item.item_name;
        });
      });
      let resArr = this.cartesian(arr);
      let newArr = [];
      resArr.forEach((item, index) => {
        let obj = {};
        item.forEach((e, i) => {
          // obj[`specItem_${i}`] = e
          if (i==0) {
            obj.comb_one_item_name = e;
          } else if (i==1) {
            obj.comb_two_item_name = e;
          } else if (i==2) {
            obj.comb_three_item_name = e;
          }
        });
        obj.comb_price = "";
        obj.comb_repertory = "";
        newArr.push(obj);
      });
      console.log("resArr==", resArr);
      console.log("newArr==", newArr);
      this.specComb = newArr;
    },
    // 笛卡尔乘积
    cartesian(arr) {
      if (arr.length < 2) {
        // 当数组只有一个的时候，每个子项独立成一个数组
        arr = arr[0].map((item) => {
          return [item];
        });
        return arr;
      }
      return [].reduce.call(arr, function (col, set) {
        let res = [];
        col.forEach((c) => {
          let t = [].concat(Array.isArray(c) ? c : [c]);
          set.forEach((s) => {
            t.push(s);
          });
          res.push(t);
        });
        return res;
      });
    },
    //
    inpChange() {
      console.log(this.specComb);
    },
    addImageStr(image) {
      this.formList.prodHomeImg = image;
    },
    addImageStrs(image) {
      this.formList.prodBannerImg = image;
    },
    // 上传封面
    async onFileChange(event) {
      // 根据这个 <input> 获取文件的 HTML5 js 对象
      var files = event.target.files;
      if (files&&files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        this.formList.prodHomeImg = data.data[0].src;
      }
    },
    // 上传轮播图
    async onFileChange1(event) {
      var files = event.target.files;
      if (files&&files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        if (this.formList.prodBannerImg.length < 6) {
          this.formList.prodBannerImg.push(data.data[0].src);
          this.formList = { ...this.formList };
          this.$refs.fileRefs1.value = "";
        } else {
          this.$message({
            showClose:true,
            message:"最多只能添加6张图片哦",
            type:"warning",
          });
          return;
        }
      }
    },
    // 上传规格图
    async onFileChange2(event, index, subIndex) {
      console.log(index, subIndex, event.target.files.length);
      var files = event.target.files;
      if (files&&files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        this.specGroup[index].storeProdSpecItems[subIndex].item_img =
          data.data[0].src;
        this.specGroup = [...this.specGroup];
        console.log(this.specGroup);
        // this.$refs.specImg[subIndex].value = "";
        event.value = "";
      }
    },
    onSendImg() {
      this.$refs.fileRefs.click();
    },
    onSendImg1() {
      this.$refs.fileRefs1.click();
    },
    onClose() {
      this.formList.prodHomeImg = "";
    },
    onClose1(index) {
      console.log("删除展示图", this.formList);
      this.formList.prodBannerImg.splice(index, 1);
      // this.formList.prodBannerImg = (this.formList.bannerImg||[]).filter(
      //   (value) => {
      //     return value != this.formList.prodBannerImg.index;
      //   }
      // );
    },
    onClose2(index, subIndex) {
      this.specGroup[index].storeProdSpecItems[subIndex].item_img = "";
      this.specGroup = [...this.specGroup];
    },
    handleClick(tab, event) {},

    /**
     * 标签相关
     */
    // 删除标签
    handleClose(tag) {
      this.formList.prodTag.splice(this.formList.prodTag.indexOf(tag), 1);
      console.log("prodTag==", this.prodTag);
    },
    // 显示input
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.focus();
      });
    },
    // 确认保存标签
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.formList.prodTag.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>
<style lang="less" scoped>
.addScenery {
  .el-breadcrumb {
    height:35px;
    border-bottom:1px solid rgb(192, 191, 191);
  }
  /deep/.el-tabs {
    .btn {
      width:400px;
      margin:20px auto;
    }
    margin-top:50px;
    .el-tabs__content {
      padding:30px;
      .tag_box {
        .el-form-item__content {
          line-height:34px;
          display:flex;
          align-items:center;
          flex-wrap:wrap;
          .el-tag,
          .el-tag--light,
          button {
            margin:5px 20px 5px 0;
          }
        }
      }
      #pane-1,
      #pane-2 {
        /deep/.el-form-item {
          .el-form-item__content {
            display:flex;
            .img {
              position:relative;
              margin:0 20px 20px;
              height:100px;
              width:100px;
              img {
                height:100px;
                width:100px;
                border:1px solid #c0ccda;
              }
              div {
                position:absolute;
                top:0;
                right:5px;
                height:10px;
                width:10px;
                z-index:100;
              }
            }
            .addImg {
              position:relative;
              display:inline-block;
              width:100px;
              height:100px;
              border:1px dashed #c0ccda;
              text-align:center;
              line-height:100px;
              cursor:pointer;
              input {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                opacity:0;
              }
              i {
                width:50px;
                height:50px;
                font-size:20px;
                color:#c0ccda;
              }
            }
          }
        }
      }
      #pane-3 {
        /deep/.el-textarea {
          height:200px;
          width:800px;
          .el-textarea__inner {
            height:200px;
          }
        }
        /deep/.quill-editor {
          height:400px;
          width:800px;
          .ql-container {
            height:300px;
            .ql-editor {
              height:300px;
            }
          }
        }
      }
      .el-tab-pane {
        .el-form {
          .map {
            /deep/ .el-form-item__content {
              height:320px;
              .container {
                height:270px;
                width:710px;
                .search-box {
                  #searchTip {
                    width:710px !important;
                    .amap-ui-poi-picker-sugg {
                      .amap-ui-poi-picker-sugg-list {
                        width:710px !important;
                      }
                    }
                  }
                }
              }
            }
          }
          /deep/ .col {
            display:inline-block;
            .el-form-item__content {
              width:300px;
              .el-input {
                width:305px;
              }
            }
          }
          .street {
            .el-form-item__content {
              .el-select {
                width:175px;
              }
            }
          }
          .el-form-item__content {
            .el-select {
              width:350px;
            }
            .el-input {
              max-width:700px;
            }
          }
        }
      }
    }
  }
  /deep/.spec_box {
    .spec_item {
      display:flex;
      align-items:center;
      .el-input {
        margin:0 24px 0 0;
        width:200px;
      }
    }
    .spec_sub_box {
      margin:24px 0 0 70px;
      display:flex;
      // align-items:center;
      flex-wrap:wrap;
      .spec_sub_btn {
        margin:0 0 24px 0;
        height:40px;
      }
      .spec_sub_item {
        margin:0 0 24px 20px;
        .s_i_t {
          display:flex;
          align-items:center;
          .el-input {
            width:200px;
          }
          button {
            margin:0 0 0 10px;
          }
        }

        .img {
          position:relative;
          margin:10px 0 0 49px;
          height:100px;
          width:100px;
          img {
            height:100px;
            width:100px;
            border:1px solid #c0ccda;
          }
          div {
            position:absolute;
            top:0;
            right:5px;
            height:10px;
            width:10px;
            z-index:100;
          }
        }
        .addImg {
          position:relative;
          display:block;
          margin:10px 0 0 49px;
          width:100px;
          height:100px;
          border:1px dashed #c0ccda;
          text-align:center;
          line-height:100px;
          cursor:pointer;
          input {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            opacity:0;
            cursor:pointer;
          }
          i {
            width:50px;
            height:50px;
            font-size:20px;
            color:#c0ccda;
          }
        }
      }
    }
  }
}
</style>